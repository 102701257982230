<template>
  <v-card class="custom-card">
    <v-toolbar
      v-if="items.length"
      class="custom-toolbar mt-10">
      <v-tabs
        dark
        v-model="tab"
        align-with-title
        fixed-tabs
      >
        <v-tab
          exact-active-class="selected"
          link
          v-for="item in items"
          :key="item.name"
          :to="{
            name: item.path
          }"
          style="margin-left: 0 !important;"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
    </v-toolbar>
  <router-view/>
  </v-card>
</template>
<script>
export default {
  name: 'index',
  data() {
    return {
      tab: null,
    };
  },
  created() {
  },
  computed: {
    menuList() {
      return this.$store.getters.menuList;
    },
    items() {
      const route = this.menuList.find((i) => i.path === this.$route.meta.parent);
      if (route && route.child && route.child.length) return route.child;
      if (route) {
        return [{
          name: this.$route.meta.title,
          path: this.$route.name,
        }];
      }
      return [];
    },
  },
};
</script>

<style scoped lang="scss">
.theme--dark.v-tabs .v-tab:focus::before {
  opacity: 0;
}
.theme--dark.v-tabs .v-tab:focus::before {
  opacity: 0;
}
.theme--dark.v-tabs .v-tab--active:hover::before, .theme--dark.v-tabs .v-tab--active::before {
  opacity: 0;
}

.custom-toolbar {
  background: radial-gradient(167.03% 28652.16% at 0.43% 0%,
    rgba(0, 104, 56, 0.9) 0%, rgba(49, 27, 87, 0.9) 100%);
  //border-top-left-radius: 12px;
  //border-top-right-radius: 12px;
}

.custom-card  {
  margin: 0 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.selected {
  font-weight: 900;
}
</style>
