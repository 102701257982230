import { render, staticRenderFns } from "./ToolBar.vue?vue&type=template&id=84f1f752&scoped=true"
import script from "./ToolBar.vue?vue&type=script&lang=js"
export * from "./ToolBar.vue?vue&type=script&lang=js"
import style0 from "./ToolBar.vue?vue&type=style&index=0&id=84f1f752&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84f1f752",
  null
  
)

export default component.exports