<template>
  <v-container fluid>
    <ToolBar style="margin-bottom: 20px"/>
<!--    <v-container fluid class="main-container">-->
<!--      <router-view/>-->
<!--    </v-container>-->
  </v-container>
</template>

<script>
import ToolBar from '@/components/ToolBar.vue';

export default {
  name: 'index',
  components: {
    ToolBar,
  },
  data() {
    return {
      list: [
        {
          label: 'Номенклатура',
          icon: 'list.svg',
          path: 'nomenclature',
          child: [
          ],
        },
        // { label: 'Склад', path: 'warehouse' },
        {
          label: 'Операции',
          icon: 'cog.svg',
          path: 'operations',
          child: [
            { name: 'Поставки', path: 'supplier-deliveries' },
            // { name: 'Приемка', path: 'acceptance' },
            // { name: 'Оприходование', path: 'posting' },
            // { name: 'Перемещения', path: 'moving' },
            // { name: 'Списания', path: 'writeoff' },
            // { name: 'Инвентаризация', path: 'inventory' },
            { name: 'Возвраты', path: 'return' },
            { name: 'Доставка', path: 'delivery' },
          ],
        },
        {
          label: 'Остатки', icon: 'warehouse.svg', path: 'balance', child: [],
        },
        {
          label: 'Home', icon: 'basket.svg', path: 'home', child: [],
        },
        // { label: 'Акт приемки', path: 'reception' },
        // { label: 'Поставки', path: 'supplier-deliveries' },
      ],
    };
  },
  created() {
    this.$store.dispatch('actionSetMenu', this.list);
  },
};
</script>

<style scoped>
.main-container {
  position: relative;
  background: #F7F7F6;
  border: 1px solid #CFD0C9;
  box-sizing: border-box;
  border-radius: 4px;
  width: calc(100% - 30px);
  margin: 10px;
  height: calc(100% - 20px);
}

</style>
